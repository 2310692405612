/*!
 * Overwrites for bootstrap print styles
 * Designed and built with all the love for trees.
 */

@media print {
    a[href]:after {
        content: none;
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: none;
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    @page {
        size: auto;
        margin: 0.5cm;
    }
    body {
        padding: 15px;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    .logged-in #toolbar,
    .logged-in .tabs,
    .book_add_child,
    .book_printer,
    .comment-add,
    #search-block-form,
    .site-main-menu,
    .comment-form,
    .searchbar,
    .hero__logo,
    .main-menu,
    .secondary-menu,
    .secondary,
    #content-top,
    footer {
        display: none;
    }
    #content-head {
        margin-bottom: 30px;
    }
    select#edit-submitted-project-type {
        height: 10rem;
    }
}